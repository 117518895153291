<template>
  <div>
    <breadcrumb-bar
      :title="appTitle"
      :breadcrumbs="breadcrumbs"
    />
    <v-container
      fluid
    >
      <v-row>
        <v-col
          cols="12"
        >
          <base-material-card
            color="primary"
            icon="mdi-account-tie"
          >
            <template
              v-slot:after-heading
            >
              <div class="display-2 font-weight-light">
                {{ $t('titlePartners') }}
                <v-menu offset-y>
                  <template v-slot:activator="{ on }">
                    <v-btn
                      v-if="showAddPartner"
                      absolute
                      dark
                      fab
                      top
                      right
                      small
                      color="indigo"
                      v-on="on"
                      @click="onAddNewPartner"
                    >
                      <v-icon>mdi-plus</v-icon>
                    </v-btn>
                  </template>
                </v-menu>
              </div>
            </template>
            <v-text-field
              v-model="partner_search"
              append-icon="mdi-magnify"
              class="ml-auto"
              :label="$t('dataTableSearch')"
              hide-details
              single-line
              style="max-width: 250px;"
            />

            <v-divider class="mt-1" />

            <v-data-table
              v-model="selected"
              single-select
              :headers="partner_headers"
              :items="partners"
              item-key="partnerId"
              :search.sync="partner_search"
              :sort-by="[]"
              :sort-desc="[]"
              multi-sort
            >
              <template v-slot:item.actions="{ item }">
                <v-btn
                  small
                  depressed
                  class="mr-2"
                  color="primary"
                  @click="onViewPartnerDetail(item)"
                >
                  <v-icon>
                    mdi-magnify
                  </v-icon>
                </v-btn>
                <v-btn
                  v-if="showAddPartner"
                  small
                  depressed
                  class="ml-2"
                  color="success"
                  @click="onPartnerEdit(item)"
                >
                  <v-icon>
                    mdi-pencil
                  </v-icon>
                </v-btn>
                <v-btn
                  v-if="showAddPartner"
                  class="ml-2"
                  outlined
                  small
                  color="error"
                  @click="onPartnerDelete(item)"
                >
                  <v-icon>
                    mdi-delete
                  </v-icon>
                </v-btn>
              </template>
            </v-data-table>
          </base-material-card>
        </v-col>
      </v-row>
    </v-container>
    <error-dialog
      dialog-type="error"
      :dialog="showErrorDialog"
      :title="$t('errorTitleProcess')"
      :message="errorMessage"
      @update:dialog="showErrorDialog = false"
    />
    <process-overlay
      :processing="modelProcessing"
    />
  </div>
</template>

<script>
  import { mapFields } from 'vuex-map-fields'
  import partnerAPI from '@/services/partnerServices'
  import verifyAPI from '@/authentication/authorization'
  export default {
    name: 'Partners',
    components: {
      BreadcrumbBar: () => import('@/views/components/core/BreadcrumbBar'),
      ErrorDialog: () => import('@/views/components/core/ErrorDialog'),
      ProcessOverlay: () => import('@/views/components/core/Overlay'),
    },
    data: function () {
      return {
        appTitle: this.$t('titlePartners'),
        breadcrumbs: [
          {
            text: this.$t('bcHome'),
            disabled: false,
            href: '/dashboard',
          },
          {
            text: this.$t('bcMasterdata'),
            disabled: true,
            href: '/masterdata',
          },
          {
            text: this.$t('bcPartners'),
            disabled: true,
            href: '/masterdata/partners',
          },
        ],
        showAddPartner: false,
        showErrorDialog: false,
        errorMessage: '',
        environment: process.env.VUE_APP_ENVIRONMENT,
        modelProcessing: false,
        selected: [],
        partner_search: '',
        partners: [],
        partner_headers: [
          {
            text: this.$t('colPartnerName'),
            value: 'name',
            class: 'primary lighten-4 display-1 font-weight-bold',
          },
          {
            text: this.$t('colPartnerType'),
            value: 'partnerTypeText',
            class: 'primary lighten-4 display-1 font-weight-bold',
          },
          {
            text: this.$t('colPartnerLocation'),
            value: 'countryCode',
            class: 'primary lighten-4 display-1 font-weight-bold',
          },
          {
            text: this.$t('colActions'),
            value: 'actions',
            class: 'primary lighten-4 display-1 font-weight-bold',
          },
        ],
      }
    },
    computed: {
      ...mapFields({
        currentUser: 'currentUser',
      }),
    },
    mounted: function () {
      this.modelProcessing = true
      if (verifyAPI.isAuthorized(this.currentUser, 1)) {
        this.showAddPartner = true
      }
      partnerAPI.getAllPartners()
        .then(response => {
          this.partners = response.data
          this.modelProcessing = false
        })
        .catch(error => {
          this.showErrorDialog = true
          this.errorMessage = error.message
          this.modelProcessing = false
        })
    },
    methods: {
      onAddNewPartner: function () {
        this.$router.push('PartnerDetails/0')
      },
      onPartnerEdit: function (item) {
        this.$router.push(`PartnerDetails/${item.partnerId}`)
      },
      onPartnerDelete: function (item) {
        if (confirm(`${this.$t('confirmPartnerDelete')} ${item.name}\n${this.$t('confirmContinue')}`)) {
          this.modelProcessing = true
          partnerAPI.deletePartner(item.partnerId)
            .then(response => {
              alert(this.$t('alertPartnerDelete'))
              partnerAPI.getAllPartners()
                .then(response => {
                  this.partners = response.data
                  this.modelProcessing = false
                })
                .catch(error => {
                  this.showErrorDialog = true
                  this.errorMessage = error.message
                  this.modelProcessing = false
                })
            })
            .catch(error => {
              this.modelProcessing = false
              this.errorMessage = error.errorMessage
              this.showErrorDialog = true
            })
        }
      },
      onViewPartnerDetail: function (item) {
        this.$router.push(`/masterdata/partners/detail/${item.partnerId}`)
      },
    },
  }
</script>
